import React, { ReactNode } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import { ThemeProvider } from 'emotion-theming';
import Image from 'gatsby-image';
import Search from './Search';
import PC_BEAKPOINT from '../constants';
import useDarkMode from './useDarkMode';
import { lightTheme, darkTheme, modeThemeStyled } from './theme';
import ThemeSwitcher from './ThemeSwitcher';

import 'normalize.css';
import 'prismjs/themes/prism-okaidia.css';
import './all.scss';

const FooterLinkWrapper = styled.div`
  margin-top: 1.6rem;
  padding: 0 0.8rem;
`;

// eslint-disable-next-line no-underscore-dangle
declare let __PATH_PREFIX__: string;

const ContainerInner = styled.div`
  font-size: 1.6rem;
  line-height: 1.6;
  padding-bottom: 1.6rem;
  margin-left: auto;
  margin-right: auto;
`;

const ModeThemeWrapper = modeThemeStyled.div`
  color: ${({ theme }): string => theme.color};
  background-color: ${({ theme }): string => theme.backgroundColor};
  transition: ${({ theme }): string => theme.transition};

  a {
    color: ${({ theme }): string => theme.linkColor};
  }

  blockquote {
    color: ${({ theme }): string => theme.backquoteColor};
    border-color: ${({ theme }): string => theme.backquoteLineColor};
  }

  .gatsby-resp-image-image {
    border: 1px solid ${({ theme }): string => theme.imageShadowColor};
    box-shadow: 4px 4px ${({ theme }): string =>
      theme.imageShadowColor} !important;
  }
`;

const H1 = styled.h1`
  line-height: 1.1;
  margin: 0 0 0.4rem;
`;

const H3 = styled.h3`
  font-size: 2.1rem;
  line-height: 1.1;
  margin: 0;
  padding: 0.9rem 0.8rem;

  @media (min-width: ${PC_BEAKPOINT}) {
    margin: 0 auto 1.6rem;
    max-width: 80rem;
    padding: 1.6rem 0 0;
  }
`;

const Hero = styled.div`
  margin-bottom: 1.6rem;
  overflow: hidden;
  position: relative;
`;

const HeroTitle = styled.div`
  color: white;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 3.6rem;
  left: 1.6rem;
  position: absolute;
  text-decoration: none;
  top: 0.8rem;
  z-index: 100;

  &:hover {
    text-decoration: none;
  }

  @media (min-width: ${PC_BEAKPOINT}) {
    bottom: -3vw;
    left: auto;
    top: auto;
    width: 100vw;
    text-align: right;
    font-size: 15vw;
  }
`;

const Header = styled.header`
  position: relative;
`;

const Main = styled.main`
  @media (min-width: ${PC_BEAKPOINT}) {
    margin: 0 auto;
    max-width: 80rem;
  }
`;

const Footer = styled.footer`
  @media (min-width: ${PC_BEAKPOINT}) {
    margin: 0 auto;
    max-width: 80rem;
  }
`;

type TemplateWrapperProps = {
  location: Location;
  title: string;
  children: React.ReactNode;
};

type ContainerProps = {
  isDarkMode: boolean;
  toggleTheme: () => void;
  header: ReactNode;
  children: ReactNode;
};

const Container: React.FC<ContainerProps> = ({
  isDarkMode,
  toggleTheme,
  header,
  children,
}: ContainerProps) => {
  return (
    <ContainerInner>
      <Header>
        <header>
          <ThemeSwitcher isDarkMode={isDarkMode} toggleTheme={toggleTheme} />
          <div>{header}</div>
        </header>
      </Header>
      <Main>{children}</Main>
      <Footer>
        <Search />
        <FooterLinkWrapper>
          © {new Date().getFullYear()}, Built with{' '}
          <a
            href="https://www.gatsbyjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Gatsby
          </a>
        </FooterLinkWrapper>
      </Footer>
    </ContainerInner>
  );
};

const TemplateWrapper: React.FC<TemplateWrapperProps> = ({
  location,
  title,
  children,
}: TemplateWrapperProps) => {
  const data = useStaticQuery(graphql`
    query BigQurey {
      hero: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  let header;
  const rootPath = `${__PATH_PREFIX__}/`;
  const [isDarkMode, toggleTheme, componentMounted] = useDarkMode();

  if (location.pathname === rootPath) {
    header = (
      <>
        <H1>
          <Link to="/">
            <Hero>
              <Image alt={title} fluid={data.hero.childImageSharp.fluid} />
              <HeroTitle>{title}</HeroTitle>
            </Hero>
          </Link>
        </H1>
      </>
    );
  } else {
    header = (
      <H3>
        <Link
          style={{
            boxShadow: 'none',
            color: 'inherit',
            textDecoration: 'none',
          }}
          to="/"
        >
          {title}
        </Link>
      </H3>
    );
  }

  if (!componentMounted) {
    if (typeof window !== `undefined`) {
      const darkModeMediqQuery = window.matchMedia(
        '(prefers-color-scheme: dark)'
      );
      return (
        <ThemeProvider
          theme={darkModeMediqQuery.matches ? darkTheme : lightTheme}
        >
          <ModeThemeWrapper>
            <Container
              isDarkMode={darkModeMediqQuery.matches}
              toggleTheme={toggleTheme}
              header={header}
            >
              {children}
            </Container>
          </ModeThemeWrapper>
        </ThemeProvider>
      );
    } else {
      return null;
    }
  }

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <ModeThemeWrapper>
        <Container
          isDarkMode={isDarkMode}
          toggleTheme={toggleTheme}
          header={header}
        >
          {children}
        </Container>
      </ModeThemeWrapper>
    </ThemeProvider>
  );
};

export default TemplateWrapper;
