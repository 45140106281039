import styled, { CreateStyled } from '@emotion/styled';

export interface ModeTheme {
  backgroundColor: string;
  color: string;
  transition: string;
  linkColor: string;
  transform: string;
  buttonBgColor: string;
  backquoteColor: string;
  backquoteLineColor: string;
  imageShadowColor: string;
}

export const lightTheme: ModeTheme = {
  backgroundColor: '#ffffff',
  color: '#000000',
  transition: '0.5s',
  linkColor: '#0c7b93',
  transform: 'translateY(0)',
  buttonBgColor: '#f9f6f7',
  backquoteColor: '#6a737d',
  backquoteLineColor: '#dfe2e5',
  imageShadowColor: '#1b262c',
};

export const darkTheme: ModeTheme = {
  backgroundColor: '#1e1e1e',
  color: '#ffffff',
  transition: '0.5s',
  linkColor: '#00a8cc',
  transform: 'translateY(100px)',
  buttonBgColor: '#1b262c',
  backquoteColor: '#f2f2f2',
  backquoteLineColor: '#f2f2f2',
  imageShadowColor: '#1b262c',
};

export const modeThemeStyled: CreateStyled<ModeTheme> = styled;
