import React from 'react';

type Props = {
  str: string;
  includes: string;
};

const TextHighlighter: React.FC<Props> = (props: Props) => {
  const { includes } = props;
  let { str } = props;
  str = str || '';
  const temp = str.toLowerCase();
  const start = temp.indexOf(includes.toLowerCase());
  const end = start + includes.length;
  const item = str.slice(start, end);
  const res = str.replace(
    item,
    `<span style="background: rgba(210, 255, 240, 0.4); padding: 3px 0">${item}</span>`
  );
  // eslint-disable-next-line react/no-danger
  return <span dangerouslySetInnerHTML={{ __html: res }} />;
};

export default TextHighlighter;
