import React from 'react';
import styled, { CreateStyled } from '@emotion/styled';
import { modeThemeStyled } from './theme';
import PC_BEAKPOINT from '../constants';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

interface Props {
  isDarkMode: boolean;
  toggleTheme: () => void;
}

const iconStyled: CreateStyled<{
  transition: string;
  position: string;
  top: number;
  left: number;
  transform: string;
}> = styled;

const ToggleContainer = modeThemeStyled.button`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1000;
  font-size: 2.4rem;
  padding: 0.8rem;
  border-radius: 100%;
  overflow: hidden;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  transition: 0.5s;

  @media (min-width: ${PC_BEAKPOINT}) {
    background-color: ${({ theme }): string => theme.buttonBgColor};
    position: fixed;
    right: 1.6rem;
    top: 1.6rem;
    bottom: auto;
  }

  .icon-wrapper {
    position: relative;
    display: block;
    height: 2.4rem;
    width: 2.4rem;
    line-height: 1;
  }
`;

const ThemeIcon = iconStyled.div<{ isDarkMode: boolean }>`
  transition: all 0.3s linear;
  position: absolute;
  top: 0;
  left: 0;

  &.light {
    transform: ${({ isDarkMode }): string =>
      isDarkMode ? 'translateY(-4.8rem)' : 'translateY(0)'};
  }

  &.dark {
    transform: ${({ isDarkMode }): string =>
      isDarkMode ? 'translateY(0)' : 'translateY(4.8rem)'};
  }
`;

const onClick = (fn: () => void, isDarkMode: boolean): void => {
  trackCustomEvent({
    category: `Theme Switcher`,
    action: `Click`,
    label: isDarkMode ? 'dark' : 'light',
  });
  fn();
};

const ThemeSwitcher: React.FC<Props> = ({ isDarkMode, toggleTheme }: Props) => {
  return (
    <ToggleContainer onClick={(): void => onClick(toggleTheme, isDarkMode)}>
      <div className="icon-wrapper">
        <ThemeIcon className="icon light" isDarkMode={isDarkMode}>
          <span role="img" aria-label="light">
            🌞
          </span>
        </ThemeIcon>
        <ThemeIcon className="icon dark" isDarkMode={isDarkMode}>
          <span role="img" aria-label="light">
            🌜
          </span>
        </ThemeIcon>
      </div>
    </ToggleContainer>
  );
};

export default ThemeSwitcher;
