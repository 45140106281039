import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import TextHighlighter from './Highlight';
import getOnlyPublished from '../utils/functions';
import { MarkdownRemarkEdge, MarkdownRemark } from '../../types/graphql-types';

const SearchContainer = styled.div`
  padding: 0 0.8rem;
`;

const SearchedSummary = styled.div`
  margin: 0.8rem 0;
`;

const SearchResults = styled.ul`
  margin: 0 0 0.8rem;
  margin-left: 0;
  padding: 0 0.8rem;
`;

interface Props {
  value: string;
  focus: number;
}

const SearchResult: React.FC<Props> = ({ value, focus }: Props) => {
  const baseData = useStaticQuery(graphql`
    query SearchData {
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 1000
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              date(formatString: "YYYY-MM-DD")
              title
              tags
              published
            }
          }
        }
      }
    }
  `);
  const [data, setData] = useState<MarkdownRemark[]>([]);
  const allPosts: Array<MarkdownRemarkEdge> = baseData.allMarkdownRemark.edges;
  const posts =
    process.env.NODE_ENV === 'production'
      ? getOnlyPublished(allPosts)
      : allPosts;

  useEffect(() => {
    setData(posts.map(({ node: post }) => post));
  }, []);

  const [result, setResult] = useState<MarkdownRemark[]>([]);
  const [className, setClassName] = useState('');
  useEffect(() => {
    let id: NodeJS.Timer;
    if (focus && value !== '') {
      id = setTimeout(() => {
        setClassName('active');
      }, 100);
    } else {
      id = setTimeout(() => {
        setClassName('');
      }, 100);
    }
    return (): void => {
      clearTimeout(id);
    };
  }, [focus, value]);

  const search = (): void => {
    const v = value.toLowerCase();
    const temp = data.filter(node => {
      const target = `${node.frontmatter.title.toLowerCase()} ${node.frontmatter.tags
        .join(' ')
        .toLowerCase()}`;
      return target.indexOf(v) !== -1;
    });
    setResult(temp);
  };
  useEffect(() => {
    if (value !== '') {
      search();
    }
  }, [value]);
  return (
    <div className={className} style={{ position: 'relative' }}>
      <div className="result-inner">
        <SearchedSummary>
          <b>{result.length}</b>
          hit
          {result.length > 1 ? 's' : ''}
        </SearchedSummary>
        <SearchResults>
          {result.map(node => {
            return (
              <li key={node.fields.slug}>
                <Link to={node.fields.slug}>
                  <TextHighlighter
                    str={node.frontmatter.title || ''}
                    includes={value}
                  />
                </Link>
              </li>
            );
          })}
        </SearchResults>
      </div>
    </div>
  );
};

const Search: React.FC = () => {
  const [focus, setFocus] = useState<number>(0);
  const [value, setValue] = useState<string>('');
  const onFocus = (): void => {
    setFocus(1);
  };
  const onBlur = (): void => {
    setFocus(0);
  };
  const onChange = (element: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(element.target.value);
  };

  return (
    <SearchContainer>
      <input
        type="text"
        placeholder="search..."
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
      />
      <SearchResult focus={focus} value={value} />
    </SearchContainer>
  );
};

export default Search;
