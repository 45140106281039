import { useEffect, useState } from 'react';

const useDarkMode = (): [boolean, () => void, boolean] => {
  if (typeof window !== `undefined`) {
    const localTheme = window.localStorage.getItem('theme');
    const darkModeMediqQuery = window.matchMedia(
      '(prefers-color-scheme: dark)'
    );
    let initialIsDarkMode: boolean;
    if (localTheme && localTheme === 'dark') {
      initialIsDarkMode = true;
    } else if (!localTheme) {
      initialIsDarkMode = darkModeMediqQuery.matches;
    } else {
      initialIsDarkMode = false;
    }
    const [isDarkMode, setIsDarkMode] = useState<boolean>(initialIsDarkMode);
    const [componentMounted, setComponentMounted] = useState<boolean>(false);

    const toggleTheme = (): void => {
      if (isDarkMode) {
        window.localStorage.setItem('theme', 'light');
        setIsDarkMode(false);
      } else {
        window.localStorage.setItem('theme', 'dark');
        setIsDarkMode(true);
      }
    };

    const darkModeChangeHandler = (event: MediaQueryListEvent) => {
      setIsDarkMode(event.matches);
      window.localStorage.setItem('theme', event.matches ? 'dark' : 'light');
    };

    useEffect(() => {
      darkModeMediqQuery.addListener(darkModeChangeHandler);
      setComponentMounted(true);

      return (): void => {
        darkModeMediqQuery.removeListener(darkModeChangeHandler);
      };
    }, []);

    return [isDarkMode, toggleTheme, componentMounted];
  } else {
    return [true, () => void 0, false];
  }
};

export default useDarkMode;
